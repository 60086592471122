export const LIGHTHOUSE_DATA = [
  { id: 0, title: "performance", score: 0.98 },
  { id: 1, title: "accessibility", score: 1.0 },
  { id: 2, title: "best practices", score: 1.0 },
  { id: 3, title: "seo", score: 1.0 },
]

export const HOCKEY_PLAYER = [
  "Since you're already here snooping around my console, plz enjoy this ascii art:",
  "─────────────────────────────▄██▄",
  "─────────────────────────────▀███",
  "────────────────────────────────█",
  "───────────────▄▄▄▄▄────────────█",
  "──────────────▀▄────▀▄──────────█",
  "──────────▄▀▀▀▄─█▄▄▄▄█▄▄─▄▀▀▀▄──█",
  "─────────█──▄──█────────█───▄─█─█",
  "─────────▀▄───▄▀────────▀▄───▄▀─█",
  "──────────█▀▀▀────────────▀▀▀─█─█",
  "──────────█───────────────────█─█",
  "▄▀▄▄▀▄────█──▄█▀█▀█▀█▀█▀█▄────█─█",
  "█▒▒▒▒█────█──█████████████▄───█─█",
  "█▒▒▒▒█────█──██████████████▄──█─█",
  "█▒▒▒▒█────█───██████████████▄─█─█",
  "█▒▒▒▒█────█────██████████████─█─█",
  "█▒▒▒▒█────█───██████████████▀─█─█",
  "█▒▒▒▒█───██───██████████████──█─█",
  "▀████▀──██▀█──█████████████▀──█▄█",
  "──██───██──▀█──█▄█▄█▄█▄█▄█▀──▄█▀",
  "──██──██────▀█─────────────▄▀▓█",
  "──██─██──────▀█▀▄▄▄▄▄▄▄▄▄▀▀▓▓▓█",
  "──████────────█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──███─────────█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──██──────────█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──██──────────█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──██─────────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──██────────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█",
  "──██───────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▌",
  "──██──────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▌",
  "──██─────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▌",
  "──██────▐█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▌",
]
