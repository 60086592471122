import React from "react"
import colors from "../core/styles/colors"

const SunMoon = ({ ...props }) => (
  <svg id="sunMoon" width="48.286289" height="48.296368">
    <g
      id="g20"
      transform="matrix(4.1486304,0,0,4.1486304,-2.2006928,-1181.6512)"
    >
      <path
        id="path2"
        d="m 6.2711185,287.94985 a 2.7010013,2.7010013 0 0 0 -2.6217742,2.69988 2.7010013,2.7010013 0 1 0 5.4018538,0 2.7010013,2.7010013 0 0 0 -2.7800796,-2.69988 z m 0.078799,0.25789 v 4.88468 a 2.4423408,2.4423408 0 0 1 0,-4.88468 z"
        fill={props.props ? colors.primary : colors.complimentary}
      />
      <path
        id="path2-3"
        d="m 6.5757545,293.32669 a 2.7010012,2.7010012 0 0 0 2.62177,-2.69988 2.7010012,2.7010012 0 1 0 -5.40185,0 2.7010012,2.7010012 0 0 0 2.78008,2.69988 z m -0.0788,-0.25789 v -4.88468 a 2.4423407,2.4423407 0 0 1 0,4.88468 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path4"
        d="m 10.283037,286.53405 -1.3483603,1.34832 a 0.12953382,0.12953382 0 0 0 0.1834294,0.18294 l 1.3476909,-1.34832 a 0.12935123,0.12935123 0 0 0 -0.18276,-0.18294 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />

      <path
        id="path8"
        d="m 10.465797,294.58299 -1.3476909,-1.34833 a 0.12953141,0.12953141 0 0 0 -0.1834294,0.18293 l 1.3483603,1.34832 a 0.12935123,0.12935123 0 0 0 0.18276,-0.18292 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path10"
        d="m 6.479452,296.34144 v -1.90681 a 0.12937727,0.12937727 0 0 0 -0.2587545,0 v 1.90681 a 0.12937727,0.12937727 0 0 0 0.2587545,0 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path12"
        d="m 2.416979,294.76591 1.3484938,-1.34832 a 0.12935122,0.12935122 0 0 0 -0.1827605,-0.18293 l -1.3484938,1.34833 a 0.12935122,0.12935122 0 0 0 0.1827605,0.18292 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path14"
        d="m 0.6585452,290.77933 h 1.9069434 a 0.12935122,0.12935122 0 0 0 0,-0.25869 H 0.6585452 a 0.12935122,0.12935122 0 0 0 0,0.25869 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path16"
        d="m 2.2342185,286.71699 1.3484938,1.34832 a 0.12935122,0.12935122 0 0 0 0.1827605,-0.18294 L 2.416979,286.53405 a 0.12935122,0.12935122 0 0 0 -0.1827605,0.18294 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
      <path
        id="path18"
        d="m 6.2206975,284.95853 v 1.90682 a 0.12937727,0.12937727 0 0 0 0.2587545,0 v -1.90682 a 0.12937727,0.12937727 0 0 0 -0.2587545,0 z"
        fill={props.props ? "transparent" : colors.complimentary}
      />
    </g>
  </svg>
)

export default SunMoon
