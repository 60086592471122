const colors = {
  primary: "#21D19F",
  secondary: "#3D5A6C",
  tagline: "#14212d",
  complimentary: "#E63462",
  lightPrimary: "#F7FFF6",
  lightSecondary: "#CBD3CB",
}

export const rgbaColors = {
  primary: "49, 175, 180",
  secondary: "17, 85, 113",
  tagline: "20, 33, 45",
  complimentary: "180, 54, 49",
}

export const gradients = {
  primaryHorizontal:
    "linear-gradient(90deg, rgba(20,33,45,1) 0%, rgba(17,85,113,1) 35%, rgba(49,175,180,1) 100%)",
  primaryUpRight:
    "linear-gradient(45deg, rgba(20,33,45,1) 0%, rgba(17,85,113,1) 35%, rgba(49,175,180,1) 100%)",
  complimentaryHorizontal:
    "linear-gradient(90deg, rgba(180,54,49,1) 0%, rgba(255,234,224,1) 100%)",
}

export default colors
