import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import { FooterWrap } from "./FooterStyles"

interface FooterProps {
  props: any
}

const Footer: React.FC<FooterProps> = ({ ...props }) => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <FooterWrap lightMode={props.props}>
      <div style={{ marginBottom: "8px" }}>
        <a
          href="https://github.com/ddansab"
          target="_blank"
          rel="noreferrer"
          aria-label="Check out my code on github"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          href="https://www.linkedin.com/in/davesabin/"
          target="_blank"
          rel="noreferrer"
          aria-label="Connect with me on linkedin"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="mailto:ddansab@gmail.com?subject=You%27re%20Hired%21"
          aria-label="Send me an email"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
      <span>{year} &copy; David Sabin &#x7c; All rights reserved</span>
    </FooterWrap>
  )
}

export default Footer
