import styled from "styled-components"
import colors from "../../styles/colors"

interface FooterProps {
  lightMode: boolean
}

export const FooterWrap = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ lightMode }) =>
    lightMode ? colors.tagline : colors.lightSecondary};
  color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
  padding: 2rem 0;
  font-size: 14px;
  text-transform: lowercase;

  svg {
    font-size: 1.25rem;
    max-width: 1.25rem !important;
  }

  a {
    color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
    margin-right: 2rem;
    &:last-of-type {
      margin: 0;
    }

    &:hover {
      color: ${({ lightMode }) =>
        lightMode ? colors.primary : colors.complimentary};
    }
  }
`
