import { Row } from "./../Grid"
import styled from "styled-components"
import colors from "../../core/styles/colors"

interface LayoutProps {
  lightMode: boolean
}

export const NavbarWrap = styled(Row)<LayoutProps>`
  position: relative;
  top: 0;
  z-index: 5;
  padding: 0 1rem;
  @media (min-width: 1200px) {
    padding: 0;
  }
`

export const Wrapper = styled.div<LayoutProps>`
  background: ${({ lightMode }) =>
    lightMode ? colors.secondary : colors.lightPrimary};
  color: ${({ lightMode }) => (lightMode ? "#fff" : colors.tagline)};
  width: 100%;

  h1,
  p {
    margin-top: 0;
    z-index: 2;
  }
  .homePageP {
    max-width: 415px;
  }

  hr {
    border: 2px solid
      ${({ lightMode }) => (lightMode ? colors.primary : colors.complimentary)};
    width: 200px;
    margin: 0 0 1.5rem;
  }

  a {
    text-decoration: none;
    color: ${({ lightMode }) => (lightMode ? colors.primary : colors.complimentary)};
    font-weight: 800;
  }

  #sunMoon {
    transform: ${({ lightMode }) => (lightMode ? "rotate(45deg)" : "none")};
    transition: all 0.3s ease;
  }
`

export const ToggleBox = styled.div<LayoutProps>`
  position: relative;
  background: ${({ lightMode }) => (lightMode ? "#fff" : colors.lightPrimary)};
  border: 1px solid ${({ lightMode }) => (lightMode ? "transparent" : "#ccc")};
  width: 35px;
  height: 20px;
  border-radius: 60px;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`

export const ToggleBall = styled.div<LayoutProps>`
  position: absolute;
  top: 50%;
  left: ${({ lightMode }) => (lightMode ? "95%" : "11%")};
  transform: ${({ lightMode }) =>
    lightMode ? "translate(-100%,-50%)" : "translateY(-50%)"};
  height: 15px;
  width: 15px;
  background: ${({ lightMode }) =>
    lightMode ? colors.primary : colors.complimentary};
  border-radius: 50%;
`

export const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
`
