import React from "react"
import { Helmet } from "react-helmet"
import { graphql, StaticQuery } from "gatsby"

interface SEOProps {
  title?: string
  description?: string
  image?: string
  keywords?: string
}

const SEO: React.FC<SEOProps> = ({ title, description, image, keywords }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defualtDescription,
          defaultImage,
          url,
          defaultKeywords,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defualtDescription,
        image: `${image ? image : url + defaultImage}`,
        keywords: keywords || defaultKeywords,
      }
      return (
        <Helmet>
          <title>{seo.title}</title>
          <meta
            name="description"
            content={seo.description || defualtDescription}
          />
          <meta name="keywords" content={seo.keywords} />
          <meta name="robots" content="index, follow" />
          <link rel="preload" href="/styles/global.css" as="style" />
          <link
            rel="preload"
            href="/fonts/fonts.css"
            as="font"
            crossOrigin="anonymous"
          />
          <html lang="en" />
        </Helmet>
      )
    }}
  />
)
export default SEO

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defualtDescription: description
        defaultImage: image
        defaultKeywords: keywords
        url
      }
    }
  }
`
