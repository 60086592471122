import styled from "styled-components"

interface GridProps {
  p?: string
  m?: string
  smP?: string
  justify?: string
  align?: string
  textAlign?: string
  width?: string
  smWidth?: string
  mdWidth?: string
  height?: string
  noWrap?: boolean
  d?: string
}

export const Row = styled.div<GridProps>`
  display: flex;
  flex-wrap: ${({ noWrap }) => !noWrap && "wrap"};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  width: 100%;
  height: ${({ height }) => height};
  padding: ${({ p }) => p};
  margin: ${({ m }) => m};
  position: relative;

  @media (max-width: 767px) {
    padding: ${({ smP }) => smP};
  }
`

export const Col = styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  width: ${({ smWidth }) => (smWidth ? `${smWidth}` : "100%")};
  height: ${({ height }) => height};
  padding: ${({ p }) => p};
  margin: ${({ m }) => m};
  position: relative;

  @media screen and (min-width: 768px) {
    width: ${({ mdWidth }) => mdWidth};
  }

  @media screen and (min-width: 1024px) {
    width: ${({ width }) => width};
  }
`

export const Container = styled.div<GridProps>`
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}` : "1400px")};
  margin: 0 auto;
  padding: ${({ p }) => p};
  display: ${({ d }) => d};
  flex-wrap: ${({ noWrap }) => !noWrap && "wrap"};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};

  @media (max-width: ${({ width }) => width}) {
    padding: 0 1rem;
  }
`
