import React from "react"
import { Link } from "gatsby"

import { Row } from "../../Grid"
import SunMoon from "../../../svgs/SunMoon"
import Logo from "../../../svgs/Logo"
import { ToggleBox, ToggleBall, ToggleWrap, NavbarWrap } from "../LayoutStyles"

interface NavbarProps {
  props: any
  setIsLightMode: () => void
}

const Navbar: React.FC<NavbarProps> = ({ ...props }) => {
  return (
    <NavbarWrap lightMode={props.props}>
      <Row
        align="center"
        height="75px"
        justify="space-between"
        p="0 24px"
        smP="0 16px"
      >
        <Link to="/" style={{ lineHeight: "1", padding: "2px 0 0" }}>
          <Logo props={props.props} />
        </Link>
        <ToggleWrap>
          <SunMoon props={props.props} />
          <ToggleBox onClick={props.setIsLightMode} lightMode={props.props}>
            <ToggleBall lightMode={props.props}></ToggleBall>
          </ToggleBox>
        </ToggleWrap>
      </Row>
    </NavbarWrap>
  )
}

export default Navbar
