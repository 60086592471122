import React from "react"

import colors from "../core/styles/colors"

const Logo = ({ ...props }) => (
  <svg width="150" viewBox="0 0 3009 1022" xmlns="http://www.w3.org/2000/svg">
    <clipPath id="a">
      <path d="m4 5h3000v1012h-3000z" />
    </clipPath>
    <clipPath id="b">
      <path d="m4 5h3000v1012h-3000z" />
    </clipPath>
    <clipPath id="c">
      <path
        clipPath="url(#a)"
        clipRule="evenodd"
        d="m5 511v506l1499.25-.25 1499.25-.25.5-1011.5h-2999zm938 0v469h-901v-938h901zm602.15-283.1 2.35 1.9v201.8c0 221 .25 211.22-6.06 235.9-7.32 28.6-19.83 50.77-40 71a153.58 153.58 0 0 1 -78.9 42.46c-36.4 7.47-76.69 3.63-108.5-10.36-22.7-10-46.13-28.9-62-50-7.69-10.27-17-28.4-21.44-41.6-11.32-33.91-12.45-77.1-3-113.3 7.49-28.6 20.48-50.83 41.59-71.15 29.14-28.05 67.2-42.45 112.2-42.45 52.7 0 94 18.43 121.79 54.4l6.81 8.8v-142.7c0-141.3 0-142.62 2-144.6 1.82-1.82 3.3-2 16.4-2 12.7 0 14.68.22 16.75 1.9zm279.65 225.18c21.7 2.36 38 6.83 55.9 15.41a148.1 148.1 0 0 1 39.6 27.51c27.8 26.67 42.91 60.33 46.7 104 .55 6.37 1 47 1 91.25 0 78.4 0 79.78-2 81.75s-3.3 2-17 2-15.17-.17-17-2-2-3.35-2-29.15v-27.15l-5.18 7.4c-7.19 10.28-23.52 26.25-34.32 33.57-29.73 20.16-67.2 29.59-105.1 26.45-38.3-3.18-67.23-15.67-92.6-40a165.31 165.31 0 0 1 -44.8-75.22c-9.52-35.2-8.3-79.89 3.07-112.5 19.78-56.71 71.23-96.8 132.53-103.27a233.34 233.34 0 0 1 41.2-.05zm779.2 1.4c51.6 8.59 92.25 37.31 113 79.82 7.06 14.46 10.23 23.9 13.52 40.2 2.53 12.5 5.18 42.7 4.12 46.9-1.53 6 9.58 5.6-146.22 5.6h-142.52l.62 6.8c4.16 45.4 20.12 75.8 50.48 96.11 28.62 19.16 73.1 25.23 113 15.42 20.5-5 34.94-13 51-28 11.1-10.37 10.6-10.35 27-1.16 15.3 8.57 15.34 8.85 2.93 22.08-18.75 20-42.13 33.62-69.63 40.58-29.62 7.49-68.7 7.49-98.5 0-50.1-12.59-90.29-49.51-105.14-96.58-15-47.6-11.73-108.22 8-147.8a133.14 133.14 0 0 1 25.26-35.6c27-27.28 59.07-41.76 102.57-46.28 8.37-.87 41.4.34 50.5 1.86zm-526.5 8.11c.88.33 2.44 2.26 3.45 4.26s26.25 61.45 56.1 132.15 54.52 128.82 54.85 129.2 25.25-58.1 55.4-130c43.19-103 55.43-131.28 57.75-133.45l2.95-2.75h17c15.1 0 17.05.19 17.9 1.75.72 1.33-14.59 38.35-64 154.75-39.14 92.2-65.77 153.7-67 154.75-1.77 1.51-4.35 1.75-19 1.75-19.5 0-21.48-.58-24.64-7.2-6.65-13.7-127.26-300.34-127.26-302.2a5.25 5.25 0 0 1 .7-3c.84-.84 33.6-.85 35.8 0z"
      />
    </clipPath>
    <clipPath id="d">
      <path clipPath="url(#c)" d="m4 5.04h3000v1012.08h-3000z" />
    </clipPath>
    <clipPath id="e">
      <path
        clipPath="url(#a)"
        clipRule="evenodd"
        d="m189 286.4v60.6h156.75c131 0 158.89.24 169.75 1.46 40.8 4.6 66.1 14 96 35.81 26.42 19.24 41.79 37.53 56.49 67.23 9.45 19.1 14.87 34 18 49.6 8.39 41.3 3.07 78.22-17.13 118.9-11.48 23.1-19.83 35.11-34.8 50-18.75 18.66-38.2 31.09-66.5 42.47-15.22 6.13-24.6 8.77-39.9 11.26-9.19 1.49-26.45 1.68-174.45 2l-164.21.37v118.6l7.25 1.2c5.54.92 43.85 1.11 162.25.81 167-.42 163-.3 199.5-5.84 16.1-2.45 40.33-8.46 52.1-12.94 13.3-5.07 47.28-22 59.8-29.87 48.06-30 84.68-70.16 110.45-121 24.69-48.68 34.54-92.3 33.31-147.6-.76-34.46-4.92-59.9-14.66-89.46-15.58-47.4-38.69-84.7-75-121.1-25.94-26-41.25-37.94-65.72-51.25-44.66-24.3-84.2-36.16-132.3-39.71-10.29-.76-69.55-1.31-176.25-1.64l-160.73-.5z"
      />
    </clipPath>
    <clipPath id="f">
      <path
        clipPath="url(#a)"
        clipRule="evenodd"
        d="m1359.6 487.58c-45.6 7.09-78.69 34.82-92.73 77.72-5.32 16.26-7.18 29.7-7.22 52.2 0 27.1 3.43 45.12 12.32 64.1 11.71 25 28.94 42.85 52.53 54.44 18.39 9 36.6 13 60 13 42.7 0 75.88-14.86 98.48-44.1 19.35-25 28.13-55.9 26.73-93.9-1-25.8-5-43-14.71-62.5-17.09-34.31-46.3-54.9-86.5-61-12.3-1.86-36.75-1.83-48.9.06"
      />
    </clipPath>
    <clipPath id="g">
      <path
        clipPath="url(#a)"
        clipRule="evenodd"
        d="m1792 487.63c-44.2 4-77.52 24.92-96.51 60.67-12.48 23.48-18 53-15.55 83.6 2.88 36.3 13.57 62.25 34.64 84.1 17 17.66 35.42 27.34 62 32.66 12.31 2.46 44.2 2.47 56.6 0 44-8.71 75.08-36.21 89.26-79 4.74-14.29 6.57-25.6 7.25-44.9.7-19.9-.68-34.7-4.69-50.3-12.09-47-44.32-76.77-91.92-84.87-9.5-1.62-32.77-2.75-41.1-2"
      />
    </clipPath>
    <clipPath id="h">
      <path
        clipPath="url(#b)"
        clipRule="evenodd"
        d="m2559 487.16c-54.5 3.19-94 35.12-107.34 86.84-1.35 5.2-2.78 11.88-3.16 14.75l-.7 5.25h247.2v-2.8c-.08-9-8.14-34.4-14.48-45.7-5.32-9.5-14.65-21.5-21.8-28.07-25-22.94-57.3-32.74-99.7-30.27"
      />
    </clipPath>
    <g clipPath="url(#d)">
      <path
        d="m0 0h3009v1022h-3009z"
        fill={props.props ? "#fff" : colors.tagline}
      />
    </g>
    <g clipPath="url(#e)">
      <path
        d="m184 220.8h634.77v631.04h-634.77z"
        fill={props.props ? colors.primary : colors.complimentary}
      />
    </g>
    <g clipPath="url(#f)">
      <path
        d="m1254.65 481.14h260.2v272.86h-260.2z"
        fill={props.props ? "#fff" : colors.tagline}
      />
    </g>
    <g clipPath="url(#g)">
      <path
        d="m1674.38 482.39h260.5v273.12h-260.5z"
        fill={props.props ? "#fff" : colors.tagline}
      />
    </g>
    <g clipPath="url(#h)">
      <path
        d="m2442.8 481.79h257.2v117.21h-257.2z"
        fill={props.props ? "#fff" : colors.tagline}
      />
    </g>
  </svg>
)

export default Logo
