import React, { useState } from "react"

import Navbar from "./Navbar"
import Footer from "./Footer"
import { Wrapper } from "./LayoutStyles"
import { HOCKEY_PLAYER } from "../../../data/lighthouse"

interface LayoutProps {
  children: any
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(true)

  return (
    <div>
      {HOCKEY_PLAYER.map(i => console.log(i))}
      <Wrapper lightMode={isLightMode}>
        <Navbar
          props={isLightMode}
          setIsLightMode={() => setIsLightMode(!isLightMode)}
        />
        {React.Children.map(children, child =>
          React.cloneElement(child, { props: isLightMode })
        )}
        <Footer props={isLightMode} />
      </Wrapper>
    </div>
  )
}

export default Layout
